.header-img-gradient {
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: -moz-linear-gradient(top, rgba(70, 93, 110, 0.5) 0%, rgba(70, 93, 110, 1) 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(70, 93, 110, 0.5)),
      color-stop(100%, rgba(70, 93, 110, 1))
    );
    background: -webkit-linear-gradient(top, rgba(70, 93, 110, 0.5) 0%, rgba(70, 93, 110, 1) 100%);
    background: -o-linear-gradient(top, rgba(70, 93, 110, 0.5) 0%, rgba(70, 93, 110, 1) 100%);
    background: -ms-linear-gradient(top, rgba(70, 93, 110, 0.5) 0%, rgba(70, 93, 110, 1) 100%);
    background: linear-gradient(to bottom, rgba(70, 93, 110, 0.5) 0%, rgba(70, 93, 110, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
  }

  img {
    display: block;
  }
}
.m-card {
  -webkit-appearance: none !important;
  -webkit-box-shadow: 0px 4px 26px -8px rgba(0, 0, 0, 0.25) !important;
  -moz-box-shadow: 0px 4px 26px -8px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0px 4px 26px -8px rgba(0, 0, 0, 0.25) !important;
  filter: drop-shadow(0px 4px 26px -8px rgba(0, 0, 0, 0.25)) !important;
  border-radius: 10px !important;
}

.m-icon {
  color: #465d6e;
  font-size: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.buttonHoverChange:hover {
  background-color: #465d6e !important;
}

.four-not-four-text-main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.four-not-four {
  display: table-cell;
  vertical-align: middle;
}

.four-not-four h1.four-not-four-text {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

::-webkit-file-upload-button {
  cursor: pointer;
}

input[type='file'] {
  cursor: pointer;
}

.menu-btn {
  flex-grow: unset !important;
  button {
    background-color: #5f737d33;
    border-radius: 10px;
    padding: 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
.card-element {
 border: 1px solid #778899;
 border-radius: 4px;
 padding: 12px;
 margin-top: 7px;
}

.slim-scroll::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

.slim-scroll::-webkit-scrollbar
{
	height: 3px;
	background-color: #F5F5F5;
}

.slim-scroll::-webkit-scrollbar-thumb
{
	background-color: #C1E0E7;
}
