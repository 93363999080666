.loader_wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
}

.loader_image {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}
